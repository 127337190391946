<template>
  <div>
    <b class="ml-5">{{ $t('listado_pacientes') }} {{ perfil.nombre }}{{ perfil.apellidos }}</b>
    <b-card class="mt-3 mb-5 ml-5 mr-5">
    <b-table
        style="font-size: 90%;"
        responsive
        id="tablaResultados"
        :items="usuarios"
        :fields="camposTabla"
        small
        hover
      >
      <template #cell(accion)="row" align="center">
          <b-button-group class="col-md-5" align="center" size="sm">
            <b-button size="sm" variant="outline-primary" @click="resultadosUsuario(row.item)" class="mr-1"
              ><i class="fas fa-file-alt fa-lg"></i>
            </b-button>
            <b-button size="sm" variant="outline-primary" @click="descargaPDF(row.item)" class="mr-1"
              ><i class="fas fa-download fa-lg"></i>
            </b-button>
          </b-button-group>
        </template>
        <template #cell(disponible)="row" align="center">
          <b-form-checkbox
            id="checkDisponible"
            v-model="disponible"
            name="checkDisponible"
            :value=resultadoDisponible(row.item)
            unchecked-value="not_accepted"
            disabled
          >
          </b-form-checkbox>
        </template>
        <template #cell(fechaNacimiento)="row" align="center">
         {{ formatearFecha(row.item.fechaNacimiento) }}
        </template>
    </b-table>
  </b-card>
  <div
      style="position:fixed;
      right: 0; 
        bottom: 0; 
        left: 0; 
        padding: 0px 0px 0px 0px; 
        background-color: #7bb021; 
        text-align: center;"
    >

      <center>
        <p style="color: #ffffff">
          <a
            href="https://mendelsbrain.com/aviso-legal/"
            style="color: #ffffff; font-size: 16px;"
            >{{ $t('terms')}}</a
          >
          |
          <a
            href="https://mendelsbrain.com/politica-de-privacidad/"
            style="color: #ffffff; font-size: 16px;"
            >{{ $t('privacy')}}</a
          >
          |
          <a
            href="https://mendelsbrain.com/politica-de-cookies/"
            style="color: #ffffff; font-size: 16px;"
            >{{ $t('cookies')}}</a
          >
          |
          <a
            href="https://mendelsbrain.com/consentimiento-informado/"
            style="color: #ffffff; font-size: 16px;"
            >{{ $t('consent')}}</a
          >
          <img
            width="90"
            class="etiqueta-ce"
            src="../images/MB_icono_label_footer_blanco.png"
            alt="Certificado CE"
          />
        </p>
      </center>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Inicio',

  data() {
    return {
      usuarios: [],
      perfil:'',
      disponible:'accepted',
      camposTablaEsp: [
        { key: "nombre", label: "Nombre", sortable: true },
        { key: "apellidos", label: "Apellidos", sortable: true },
        { key: "kit", label: "Kit", sortable: true },
        { key: "sexo", label: "Sexo", sortable: true },
        { key: "fechaNacimiento", label: "Fecha Nacimiento", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "telefono", label: "Teléfono", sortable: true },
        { key: "disponible", label: "Disponible", sortable: true , thStyle: { width: "60px" }},
        { key: "accion", label: "", thStyle: { width: "50px" } },
      ],
      camposTablaEng: [
        { key: "nombre", label: "Name", sortable: true },
        { key: "apellidos", label: "Surname", sortable: true },
        { key: "kit", label: "Kit", sortable: true },
        { key: "sexo", label: "Sex", sortable: true },
        { key: "fechaNacimiento", label: "Birth date", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "telefono", label: "Phone", sortable: true },
        { key: "disponible", label: "Available", sortable: true , thStyle: { width: "60px" }},
        { key: "accion", label: "", thStyle: { width: "50px" } },
      ],
      camposTabla:[]
    };
  },
  created() {},
  methods: {
    formatearFecha(fecha)
    {
      return fecha.substring(0,10);
    },
    resultadoDisponible(item)
    {
      if(item.estado == 4){return "accepted";}
      else {return "not_accepted";}
    },
    getUsuariosPerfil() {
      let user = JSON.parse(localStorage.getItem('user'));
      //console.log"ENTRA1:"+user);
      axios
        .get(
          'https://api.mendelbrain.com:55020/api/mendels/perfilusuario/profesional/'+user.id,
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(
          response => {
            this.usuarios = response.data;
          },
          error => {
               console.log(error);
          }
        );
    },
    getPerfilProfesional() {
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .post(
          'https://api.mendelbrain.com:55020/api/mendels/perfilusuario/profesional',
          {
            token: user.accessToken
          },
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(
          response => {
            this.perfil = response.data;
          },
          error => {
               console.log(error);
          }
        );
    },
    resultadosUsuario(item)
    {
      //console.log"ENTROs:"+item.id);
      this.$router.push({ name: "resultadosprofesional", params: { idPerfil: item.id } });
    },
    descargaPDF(item) {

      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .post(
          'https://api.mendelbrain.com:55020/api/mendels/archivo/usuario/pdf/'+item.id,
          {
            token: user.accessToken
          },
          {
            responseType: 'blob',
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(
          response => {
             // response => {
              var blob = new Blob([response.data], {
              type: response.headers['content-type']
            });
            var fileDownload = require('js-file-download');
            fileDownload(blob, item.kit+ '.pdf');
          },
          error => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }

    if(this.$parent.language === 'ESP'){this.camposTabla = this.camposTablaEsp;}
    if(this.$parent.language === 'ENG'){this.camposTabla = this.camposTablaEng;}

    this.getUsuariosPerfil();
    this.getPerfilProfesional();
  },
  components: {}
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.textoEstado {
  padding: 30px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.filaCodigoKit {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  height: 20px;
}
.imagenEstado {
  width: 650px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.cuadroTexto {
  width: 650px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.imagenKit {
  padding: 0px 0px 0px 0px;
  margin: 5px 0px 0px 0px;
  text-align: right;
  width: 30px;
  height: 35px;
}
.imagenKitTexto {
  padding: 0px 0px 0px 0px;
  margin: 10px 0px 0px 0px;
  text-align: center;
  width: 200px;
  height: 200px;
}
.codigoKit {
  border-radius: 50px 50px 50px 50px;
  border-style: solid;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 30px 0px;
  color: #252424;
  border-color: #efefef;
  background: linear-gradient(0.95turn, #399a29, #8caf36);
  text-decoration: none;
  width: 300px;
  height: 60px;
}
.codigoKitLetraG {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  color: #ffffff;
  text-align: left;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.codigoKitLetraP {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #ffffff;
  text-align: left;
  padding: 0px 0px 0px 0px;
  margin: 5px 0px 0px 0px;
}
.cuadro-rasgo-grande {
  text-align: center;
  border-width: 5px;
  border-color: rgb(236, 234, 234);
  background-color: rgb(236, 234, 234);
  margin: 10px 10px 10px 10px;
  border-radius: 20px 20px 20px 20px;
  padding: 10px 10px 10px 10px;
}

.cuadro-rasgo-pequeño {
  text-align: center;
  border-width: 5px;
  border-color: white;
  margin: 0px 0px 0px 0px;
  border-radius: 20px 20px 20px 20px;
  width: 300px;
  padding: 0px 0px 0px 0px;
  box-shadow: 0px 0px 55px 0px rgb(209, 206, 206);
}
.texto-cuadro-grande {
  color: #757575;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-align: left;
  margin: 0px 10px 30px 10px;
  padding: 0px 10px 0px 10px;
}
@media all and (min-width: 320px) and (max-width: 1410px) {
  .cuadro-rasgo-grande {
    text-align: center;
    border-width: 5px;
    border-color: rgb(236, 234, 234);
    background-color: rgb(236, 234, 234);
    margin: 20px 20px 20px 20px;
    border-radius: 20px 20px 20px 20px;
    padding: 20px 20px 20px 20px;
  }
  .cuadro-rasgo-pequeño {
    text-align: center;
    border-width: 5px;
    border-color: white;
    margin: 0px -20px 20px -20px;
    border-radius: 20px 20px 20px 20px;
    width: 300px;
    padding: 0px 0px 0px 0px;
    box-shadow: 0px 0px 55px 0px rgb(209, 206, 206);
  }
  .texto-cuadro-grande {
    color: #757575;
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    margin: 0px 10px 30px 10px;
    padding: 0px 10px 0px 10px;
  }
  @media all and (min-width: 451px) and (max-width: 700px) {
    .textoEstado {
      padding: 40px 0px 0px 10px;
      margin: 0px 0px 0px 0px;
      font-size: 15px;
    }
    .imagenEstado {
      width: 400px;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
    }
    .cuadroTexto {
      width: 400px;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
    }
    .imagenKitTexto {
      padding: 0px 0px 0px 0px;
      margin: 20px 0px 0px 0px;
      text-align: right;
      width: 130px;
      height: 130px;
    }
    .codigoKit {
      border-radius: 50px 50px 50px 50px;
      border-style: solid;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 30px 0px;
      color: #252424;
      border-color: #efefef;
      background: linear-gradient(0.95turn, #399a29, #8caf36);
      text-decoration: none;
      width: 250px;
      height: 60px;
    }
  }
  @media all and (max-width: 450px) {
    .textoEstado {
      padding: 40px 10px 0px 15px;
      margin: 0px 0px 0px 0px;
      font-size: 13px;
    }
    .filaCodigoKit {
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
      height: 20px;
    }
    .imagenEstado {
      width: 350px;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
    }
    .cuadroTexto {
      width: 350px;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
    }
    .imagenKit {
      padding: 0px 0px 0px 0px;
      margin: 5px 0px 0px 0px;
      text-align: right;
      width: 30px;
      height: 35px;
    }
    .imagenKitTexto {
      padding: 0px 0px 0px 0px;
      margin: 20px 0px 0px 0px;
      text-align: right;
      width: 120px;
      height: 120px;
    }
    .codigoKit {
      border-radius: 50px 50px 50px 50px;
      border-style: solid;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 30px 0px;
      color: #252424;
      border-color: #efefef;
      background: linear-gradient(0.95turn, #399a29, #8caf36);
      text-decoration: none;
      width: 250px;
      height: 60px;
    }
    .codigoKitLetraG {
      font-family: 'Montserrat', sans-serif;
      font-size: 20px;
      color: #ffffff;
      text-align: left;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
    }
    .codigoKitLetraP {
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      color: #ffffff;
      text-align: left;
      padding: 0px 0px 0px 0px;
      margin: 5px 0px 0px 0px;
    }
  }
}
</style>
